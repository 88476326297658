import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/app/[...uids]/components/SocialWallFlockler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScheduleSearchTable"] */ "/jenkins/workspace/handball.net_production@2/web/app/schedule-search/ScheduleSearchTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HalfPageAd"] */ "/jenkins/workspace/handball.net_production@2/web/components/Ad/HalfPageAd.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/components/Ad/LeaderBoardAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RectangleAd"] */ "/jenkins/workspace/handball.net_production@2/web/components/Ad/RectangleAd.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/components/layout/ContentHeader.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/components/router/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/table/Table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/ui/basic/Button.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/node_modules/next/dist/client/link.js");
